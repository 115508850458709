import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"

import FrontmatterPageContext from "~context/FakePageContext"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"
import LayoutCore from "./layout-core"
import { mainArea, main } from "~vanilla/layout-default-full.css"

const LayoutDefaultFull = ({ pageContext }) => {
  const { frontmatter, body } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto, CodeBlock, pre: CodeBlock }

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-content">
              <MDXProvider components={mdx_components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutDefaultFull

export const Head = (props) => {
  return (
    <>
      <title>{`${props.pageContext.frontmatter.title ?? "Fastly Docs"}`}</title>
      <CommonHead />
      <SeoHead {...props} />
      <FavHead />
    </>
  )
}
