import React from "react"
import { CodeBlock as ConsistentlyCodeBlock } from "@fastly/consistently"

export const CodeBlock = (props) => {
  const defaultLang = "plain"
  const languageMapping = {
    plaintext: defaultLang,
    text: defaultLang,
    none: defaultLang,
    "language-console": "bash",
  }
  const currentLang = props.children.props?.className
  const newLang = languageMapping[currentLang]

  // props are different depending if we're using mdx or <CodeBlock> directly
  const isMdx = props.children.props
  const children = isMdx ? isMdx.children : props.children

  const showLinenums = children.split("\n").length - 1 >= 3

  // Use explicit language setting, otherwise default to langauge specified by className
  return <ConsistentlyCodeBlock language={newLang} linenums={showLinenums} {...props} />
}
